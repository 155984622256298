import * as React from "react"
import styled from '@emotion/styled'
import Layout from '../../components/Layout/Layout';
import { Link } from 'gatsby';
import { PageTitleHeader, Breakpoints, blissfulColour } from '../../globalStyles';

const Container = styled.div`
  margin: 3rem auto;
  max-width: 960px;
  padding: 0 20px;
  text-align: center;
`

const BackButton = styled.button`
  padding: 15px 25px; 
  color: white;
  background-color: ${blissfulColour};
  border: none;
  text-align: center;
  border-radius: 15px;

  :hover {
    cursor: pointer; 
    filter: brightness(120%);
  }
`

const Thanks = () => {
  return(
  <Layout
    title="Thank you for inquiring"
    noIndex={true}
  >
    <Container>
      <PageTitleHeader> Thank you for your inquiry! </PageTitleHeader>

      <p> We look forward to working with you and will respond by the next business day. </p>
      <p> In the meantime, please have 1-3 pictures ready for inspiration. </p>

      <Link to="/">
        <BackButton>
          ← Back Home
        </BackButton>
      </Link>

    </Container>
  
  </Layout>
  )
}

export default Thanks

